import * as React from "react"
import "../scss/AboutPage.scss"
import Seo from "../components/seo/seo"

import WorkerCard from "../shared/worker-card/WorkerCard"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"

const About = props => {
  const teams = props.pageContext.resultTeams
  const menu = props.pageContext.menu
  const staticHeaderPage = props.pageContext.staticHeaderPage
  const staticAboutPage = props.pageContext.staticAboutPage
  return (
    <div>
      <div className="container">
        <Header
          menu={menu}
          siteTitle={staticAboutPage["<title>"]}
          contents={staticHeaderPage}
          displayLogoLinks={false}
        />
        <Seo
          keywordsContent={staticAboutPage["<meta> keywords"]}
          descriptionContent={staticAboutPage["<meta> description"]}
          title={staticAboutPage["<title>"]}
        />
        <div className="about-page">
          <div className="row mt-4">
            <h1 className="col">{staticAboutPage["Page Header"]}</h1>
            <div className="col-1"></div>
          </div>
          <div className="row mt-4 mb-3">
            <h3 className="col">{staticAboutPage["Page Promo 1"]}</h3>
            <div className="col-1"></div>
          </div>
          <div className="row mb-2">
            <h3 className="col">{staticAboutPage["Page Promo 2"]}</h3>
            <div className="col-1"></div>
          </div>
          <div className="row mt-3">
            <h3 className="col">{staticAboutPage["Page Promo 3"]}</h3>
            <div className="col-1"></div>
          </div>
          <div className="row mt-6">
            <h1 className="col">{staticAboutPage["Team Header"]}</h1>
          </div>
          <div className="row mt-3">
            <div className="description col-lg-6">
              {staticAboutPage["Team Promo 1"]}
            </div>
          </div>
          <div className="row mt-4">
            <div className="description col-lg-6">
              {staticAboutPage["Team Promo 2"]}
            </div>
          </div>
          <div className="container workers-list">
            <div className="workers-list-items row mt-4 mb-6 flex-wrap w-100">
              {teams.map(item => {
                return (
                  <WorkerCard
                    photo={item.imageUrl}
                    name={item.name}
                    text={item.description}
                    linkedinProfileUrl={item.linkedinProfileUrl}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer
        recaptchaSettings={props.pageContext.recaptchaSettings}
        staticContactsForm={props.pageContext.staticContactsForm}
        staticContactsPage={props.pageContext.staticContactsPage}
      />
    </div>
  )
}

export default About
