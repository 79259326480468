import React from "react"
import "./WorkerCard.scss"
import { getImageUrl } from "../../services/Utils"
import { FaLinkedin } from "react-icons/fa";

function regularItem(photo, name, text){
  return (<div className="worker-card-wrapper d-flex justify-content-center">
    <div className="worker-card curs-P">
      <div className="content">
        <div className="content-overlay"></div>
        <img className="content-image" src={getImageUrl(photo)} />
        <div className="content-details fadeIn-bottom">
          <div className="content-title">{name}</div>
          <div
              className="content-text mt-2"
              dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        </div>
      </div>
    </div>
  </div>);
}

function itemWithLinkedInUrl(photo, name, text, linkedinProfileUrl){
  return (<div className="worker-card-wrapper d-flex justify-content-center">
    <div className="worker-card curs-P">
      <div className="content">
        <div className="content-overlay"></div>
        <img className="content-image" src={getImageUrl(photo)} />
        <div className="content-details fadeIn-bottom">
          <div className="content-title">{name}</div>
          <div
              className="content-text mt-2"
              dangerouslySetInnerHTML={{ __html: text }}
          ></div>
          <a className="content-link" href={linkedinProfileUrl} target="_blank"><FaLinkedin/></a>
        </div>
      </div>
    </div>
  </div>);
}

const WorkerCard = ({ photo, name, text, linkedinProfileUrl }) => {
  if (linkedinProfileUrl) {
    return itemWithLinkedInUrl(photo, name, text, linkedinProfileUrl);
  } else {
    return regularItem(photo, name, text);
  }
}

export default WorkerCard
